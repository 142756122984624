<template>
  <div>
    <b-form @submit.prevent="" class="curva-form mb-5">
      <b-row class="d-flex justify-content-center">
        <b-col lg="7" class="mb-1">
          <b-input-group>
            <input-form :placeholder="$t('cart.coupon')" class="flex-grow-1" custom-class="curva-control-input"/>
            <b-input-group-append>
              <b-button class="input-btn">{{ $t('cart.verify') }}</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col lg="7">
          <b-form-group
              label-for="Notes"
          >
            <ValidationProvider name="notes" ref="Notes" v-slot="{ errors }">
              <b-form-textarea
                  :placeholder="$t('cart.notes')+'..'" :validate="'required'"
                  rows="2" v-model="notes" @change="storeNotes"
                  :class="['curva-textarea', errors.length > 0 ? ' is-invalid' : '']"
              ></b-form-textarea>
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col lg="7" class="mb-3 text-initial">
          <b-form-checkbox class="custom-checkbox-color-checked" plain v-model="terms"
                           color="warning" name="color" @change="acceptTerms">
            <span class="text-gray font-size-16 mx-4">{{ $t('cart.terms1') }}
            <router-link :to="{ name: 'terms' }" class="text-warning">{{ $t('cart.terms2') }}</router-link></span>
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'

export default {
  data () {
    return {
      notes: null,
      terms: false
      /* paymentMethods: [
        {
          value: 'visa',
          imgSrc: require('@/assets/images/curva/payment/visa.png')
        },
        {
          value: 'master-card',
          imgSrc: require('@/assets/images/curva/payment/masterCard.png')
        },
        {
          value: 'fawry',
          imgSrc: require('@/assets/images/curva/payment/fawry.png')
        },
        {
          value: 'smart-wallet',
          imgSrc: require('@/assets/images/curva/payment/smartWallet.png')
        }
      ],
      paymentOption: '' */
    }
  },
  methods: {
    storeNotes () {
      this.$store.commit('setNotes', this.notes)
    },
    acceptTerms () {
      this.$store.commit('setTerms', this.terms)
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.$store.commit('setTerms', this.terms)
    this.$store.commit('cartStep', 3)
  }
}
</script>
